import { EntityId, EntityState } from '@reduxjs/toolkit';
import { ClassConstructor, plainToInstance } from 'class-transformer';
import { isFunction, keys } from 'lodash';
import { BookedProperty } from '@shared/booked-property/models/booked-property';
import { Category } from '@shared/category/models/category';
import { Discount } from '@shared/discount/models/discount';
import { HistoryItem } from '@shared/history-item/models/history-item';
import { PropertiesGroup } from '@shared/properties-group/models/properties-group';
import { Property } from '@shared/property/models/property';
import { BaseEntityPlain } from './models';

export interface Entities {
  category: Category;
  property: Property;
  propertiesGroup: PropertiesGroup;
  discount: Discount;
  historyItem: HistoryItem;
  bookedProperty: BookedProperty;
}
export type EntityName = keyof Entities;
export type Entity = Entities[EntityName];
export type EntitiesState = { [key in EntityName]: EntityState<BaseEntityPlain, EntityId> };

export const ENTITIES_CONFIG: { [key in EntityName]: ClassConstructor<Entities[key]> } = {
  category: Category,
  property: Property,
  propertiesGroup: PropertiesGroup,
  discount: Discount,
  historyItem: HistoryItem,
  bookedProperty: BookedProperty
};

export const createEntityInstance = <TEntity extends Entity = Entity, TPlain extends object = object>(
  entityName: EntityName,
  data: TPlain | Entity,
  options: { fromInstancePartial?: boolean } = { fromInstancePartial: false }
): TEntity => {
  if (!isFunction(ENTITIES_CONFIG[entityName])) {
    throw new Error(
      `Trying to create unknown entity "${entityName}". Expected one of: ${keys(ENTITIES_CONFIG).join(', ')} `
    );
  }

  if (data instanceof ENTITIES_CONFIG[entityName] || options.fromInstancePartial) {
    return new ENTITIES_CONFIG[entityName](data) as TEntity;
  }

  return plainToInstance(ENTITIES_CONFIG[entityName] as ClassConstructor<any>, data);
};
