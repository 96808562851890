import { pick } from 'lodash';
import { configuration } from '@configuration';
import { PropertyPaginationRequest } from '@shared/property';
import { SearchPaginationRequest } from '@shared/search';

export function getFilterCount(filters: PropertyPaginationRequest | SearchPaginationRequest): number {
  const pickedFilters = pick(filters, ['priceFrom', 'priceTo', 'categoriesIDs', 'startAt']);
  let filtersCount = Object.values(pickedFilters).filter((value) => Array.isArray(value) ? !!value.length : !!value).length;

  if (pickedFilters.priceTo === configuration.filters.maxPossiblePrice) {
    filtersCount--;
  }

  if (filters.numberOfGuestsFrom && filters?.numberOfGuestsFrom > 1) {
    filtersCount++;
  }

  return filtersCount;
}
