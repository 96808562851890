import { reducer as modalReducer } from 'redux-modal';
import { newPasswordPageReducer } from '@app/account-access/new-password/shared/store/reducer';
import { passwordRecoveryPageReducer } from '@app/account-access/password-recovery/shared/store/reducer';
import { signupPageReducer } from '@app/account-access/signup/shared/store/reducer';
import { changePasswordPageReducer } from '@app/main/change-password/shared/store/reducer';
import { editProfilePageReducer } from '@app/main/edit-profile/shared/store/reducer';
import { homePageReducer } from '@app/main/home/shared/store/reducer';
import { premiumPageReducer } from '@app/main/premium/shared/store/reducer';
import { propertiesGroupPageReducer } from '@app/main/properties-group/shared/store/reducer';
import { propertyPageReducer } from '@app/main/property/shared/store/reducer';
import { rewardsPageReducer } from '@app/main/rewards/shared/store/reducer';
import { availableDatesModalReducer } from '@app/main/shared/available-dates-modal/store/reducer';
import { categoriesMultiselectReducer } from '@app/main/shared/categories-multiselect/store/reducer';
import { profileFormReducer } from '@app/main/shared/profile-form/store/reducer';
import { searchModalReducer } from '@app/main/shared/search-modal/store/reducer';
import { authReducer } from '@shared/auth/store/reducer';
import { entityStoreReducer } from '@shared/base-entity/store/reducer';
import { profileReducer } from '@shared/profile/store/reducer';

export const rootReducer = {
  profile: profileReducer,
  auth: authReducer,
  passwordRecoveryPage: passwordRecoveryPageReducer,
  newPasswordPage: newPasswordPageReducer,
  signupPage: signupPageReducer,
  profileForm: profileFormReducer,
  modal: modalReducer,
  editProfilePage: editProfilePageReducer,
  changePasswordPage: changePasswordPageReducer,
  entityStore: entityStoreReducer,
  categoriesMultiselect: categoriesMultiselectReducer,
  homePage: homePageReducer,
  searchModal: searchModalReducer,
  propertiesGroupPage: propertiesGroupPageReducer,
  propertyPage: propertyPageReducer,
  availableDatesModal: availableDatesModalReducer,
  premiumPage: premiumPageReducer,
  rewardsPage: rewardsPageReducer
};
