import { Expose, Type } from 'class-transformer';
import { DateTime } from 'luxon';
import { TransformBoolean, UtcTransformDate } from '@shared/class-transformer';
import { PaginationRequest } from '@shared/pagination';
import { SearchOrderBy, SearchRelation } from '../types';

export class SearchPaginationRequest extends PaginationRequest<SearchRelation, SearchOrderBy> {
  @Expose({ name: 'top_right_lat' })
  public topRightLat?: number;

  @Expose({ name: 'top_right_lon' })
  public topRightLng?: number;

  @Expose({ name: 'bottom_left_lat' })
  public bottomLeftLat?: number;

  @Expose({ name: 'bottom_left_lon' })
  public bottomLeftLng?: number;

  @Expose({ name: 'categories_ids' })
  public categoriesIDs?: Array<number>;

  @Expose({ name: 'start_at' })
  @Type(() => DateTime)
  @UtcTransformDate()
  public startAt?: DateTime;

  @Expose({ name: 'end_at' })
  @Type(() => DateTime)
  @UtcTransformDate()
  public endAt?: DateTime;

  @Expose({ name: 'has_calendar_link' })
  @TransformBoolean()
  public hasCalendarLink?: boolean;

  @Expose({ name: 'number_of_guests_from' })
  public numberOfGuestsFrom?: number;

  @Expose({ name: 'price_from' })
  public priceFrom?: number;

  @Expose({ name: 'price_to' })
  public priceTo?: number;

  @Expose({ name: 'latitude' })
  public profileLatitude?: number;

  @Expose({ name: 'longitude' })
  public profileLongitude?: number;

  constructor(model: Partial<SearchPaginationRequest>) {
    super(model);
    Object.assign(this, model);
  }
}
