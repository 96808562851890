import { Exclude, Expose, Type } from 'class-transformer';
import { BaseEntity } from '@shared/base-entity/models';
import { Category } from '@shared/category/models';
import { Location } from '@shared/location';
import { Media } from '@shared/media';
import { Owner } from '@shared/owner';
import { PropertiesGroup } from '@shared/properties-group/models';

export class Property extends BaseEntity<number> {
  @Expose()
  public name: string;

  @Expose()
  @Type(() => Media)
  public photos: Array<Media>;

  @Expose()
  @Type(() => Location)
  public location: Location;

  @Expose()
  @Type(() => Number)
  public price: number;

  @Expose({ name: 'price_text' })
  public priceText: string;

  @Expose({ name: 'number_of_adults' })
  @Type(() => Number)
  public numberOfAdults: number;

  @Expose({ name: 'number_of_children' })
  @Type(() => Number)
  public numberOfChildren: number;

  @Expose()
  public address: string;

  @Expose()
  public highlights: string;

  @Expose()
  @Type(() => Owner)
  public owner: Owner;

  @Expose()
  @Type(() => Category)
  public categories: Array<Category>;

  @Expose({ name: 'property_group' })
  @Type(() => PropertiesGroup)
  public propertiesGroup: PropertiesGroup;

  @Expose()
  public link: string;

  @Expose({ name: 'has_calendar_link' })
  public hasCalendarLink: boolean;

  @Expose({ name: 'property_group_id' })
  public propertiesGroupID: number;

  @Expose({ name: 'is_premium' })
  public isPremium: boolean;

  @Exclude()
  public get thumbnails1024(): Array<Media> {
    return this.photos.map((photo) => photo.thumbnail1024);
  }

  @Exclude()
  public get firstThumbnail1024Link(): string | null {
    return this.thumbnails1024.length > 0 ? this.thumbnails1024[0].link : null;
  }

  @Exclude()
  public get thumbnails512(): Array<Media> {
    return this.photos.map((photo) => photo.thumbnail512);
  }

  @Exclude()
  public get thumbnails512Links(): Array<string> {
    return this.photos.map((photo) => photo.thumbnail512.link);
  }

  @Exclude()
  public get firstThumbnail512Link(): string | null {
    return this.thumbnails512Links.length > 0 ? this.thumbnails512Links[0] : null;
  }

  @Exclude()
  public get thumbnails128Links(): Array<string> {
    return this.photos.map((photo) => photo.thumbnail128.link);
  }

  @Exclude()
  public get firstThumbnail128Link(): string | undefined {
    return this.thumbnails128Links.length > 0 ? this.thumbnails128Links[0] : undefined;
  }

  @Exclude()
  public get squares1024(): Array<Media> {
    return this.photos.map((photo) => photo.square1024).filter((square1024) => !!square1024);
  }

  @Exclude()
  public get firstSquare1024Link(): string | null {
    return this.squares1024.length > 0 ? this.squares1024[0].link : null;
  }

  @Exclude()
  public get numberOfGuests(): number {
    return this.numberOfAdults + this.numberOfChildren;
  }

  constructor(model: Partial<Property>) {
    super(model);

    Object.assign(this, model);
  }
}
